import Cookies from "js-cookie";
import moment from "moment-timezone";

const setSnooze = (ev: JQuery.TriggeredEvent) => {
  const expireAt = moment().startOf("day");
  if (ev.target.textContent === "1 day") {
    expireAt.add(1, "day");
  } else if (ev.target.textContent === "1 week") {
    expireAt.add(1, "week");
  } else if (ev.target.textContent === "2 weeks") {
    expireAt.add(2, "week");
  } else {
    expireAt.add(1, "year");
  }
  const delayOffboardReminderCookie = ev.target.name;
  // Must match Django template

  Cookies.set(delayOffboardReminderCookie, "true", {
    expires: expireAt.toDate(),
  });
};

$(".offboard-banner-snooze-options button").on("click", setSnooze);
